import classNames from 'classnames';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import React from 'react';
import {defineMessages, intlShape, injectIntl} from 'react-intl';
import {setProjectTitle} from '../../reducers/project-title';
import {requestSaveProjectTitle} from '../../lib/fetcher-api';

import BufferedInputHOC from '../forms/buffered-input-hoc.jsx';
import Input from '../forms/input.jsx';
const BufferedInput = BufferedInputHOC(Input);

import styles from './project-title-input.css';

function updateProjectTitle(projectId, projectTitle) {
    if (projectId && projectTitle) {
        new Promise((resolve, reject) => {
            if (process.env.NODE_ENV.trim() == 'development') {
                requestSaveProjectTitle(resolve, reject, 'http://127.0.0.1:8000/api/project/' + projectId + '/rename', projectTitle.trim());
            } else {
                requestSaveProjectTitle(resolve, reject, 'https://ottawastem.com/api/project/' + projectId + '/rename', projectTitle.trim());
            }
        }).catch ((error) => {
            console.log('Update Scratch Project Title failed! - ' + error);
        });
    }
}

const messages = defineMessages({
    projectTitlePlaceholder: {
        id: 'gui.gui.projectTitlePlaceholder',
        description: 'Placeholder for project title when blank',
        defaultMessage: 'Project title here'
    }
});

const ProjectTitleInput = ({
    className,
    intl,
    onSubmit,
    projectId,
    projectTitle,
}) => (
    <BufferedInput
        className={classNames(styles.titleField, className)}
        maxLength="100"
        placeholder={intl.formatMessage(messages.projectTitlePlaceholder)}
        tabIndex="0"
        type="text"
        value={projectTitle}
        onSubmit={updateProjectTitle(projectId, projectTitle), onSubmit}
    />
);

ProjectTitleInput.propTypes = {
    className: PropTypes.string,
    intl: intlShape.isRequired,
    onSubmit: PropTypes.func,
    projectId: PropTypes.string,
    projectTitle: PropTypes.string
};

const mapStateToProps = state => ({
    projectId: state.scratchGui.session.projectId,
    projectTitle: state.scratchGui.projectTitle
});

const mapDispatchToProps = dispatch => ({
    onSubmit: title => dispatch(setProjectTitle(title))
});

export default injectIntl(connect(
    mapStateToProps,
    mapDispatchToProps
)(ProjectTitleInput));
