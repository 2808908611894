const OPEN_MENU = 'scratch-arduino/menus/OPEN_MENU';
const CLOSE_MENU = 'scratch-arduino/menus/CLOSE_MENU';
const SET_LINK_STATUS = 'scratch-arduino/menus/setLinkStatus';

const MENU_ABOUT = 'aboutMenu';
const MENU_ACCOUNT = 'accountMenu';
const MENU_FILE = 'fileMenu';
const MENU_EDIT = 'editMenu';
const MENU_LANGUAGE = 'languageMenu';
const MENU_LOGIN = 'loginMenu';
const MENU_SERIALPORT = 'serialportMenu';
const MENU_SETTING = 'setting';

const initialState = {
    [MENU_ABOUT]: false,
    [MENU_ACCOUNT]: false,
    [MENU_FILE]: false,
    [MENU_EDIT]: false,
    [MENU_LANGUAGE]: false,
    [MENU_LOGIN]: false,
    [MENU_SERIALPORT]: false,
    [MENU_SETTING]: false,
    linkStatus: false
};

const reducer = function (state, action) {
    if (typeof state === 'undefined') state = initialState;
    switch (action.type) {
        case OPEN_MENU:
            return Object.assign({}, state, {
                [action.menu]: true
            });
        case CLOSE_MENU:
            return Object.assign({}, state, {
                [action.menu]: false
            });
        case SET_LINK_STATUS:
            return Object.assign({}, state, {
                linkStatus: action.status
            });
        default:
            return state;
    }
};
const openMenu = menu => ({
    type: OPEN_MENU,
    menu: menu
});
const closeMenu = menu => ({
    type: CLOSE_MENU,
    menu: menu
});
const openAboutMenu = () => openMenu(MENU_ABOUT);
const closeAboutMenu = () => closeMenu(MENU_ABOUT);
const aboutMenuOpen = state => state.scratchGui.menus[MENU_ABOUT];
const openAccountMenu = () => openMenu(MENU_ACCOUNT);
const closeAccountMenu = () => closeMenu(MENU_ACCOUNT);
const accountMenuOpen = state => state.scratchGui.menus[MENU_ACCOUNT];
const openFileMenu = () => openMenu(MENU_FILE);
const closeFileMenu = () => closeMenu(MENU_FILE);
const fileMenuOpen = state => state.scratchGui.menus[MENU_FILE];
const openEditMenu = () => openMenu(MENU_EDIT);
const closeEditMenu = () => closeMenu(MENU_EDIT);
const editMenuOpen = state => state.scratchGui.menus[MENU_EDIT];
const openLanguageMenu = () => openMenu(MENU_LANGUAGE);
const closeLanguageMenu = () => closeMenu(MENU_LANGUAGE);
const languageMenuOpen = state => state.scratchGui.menus[MENU_LANGUAGE];
const openLoginMenu = () => openMenu(MENU_LOGIN);
const closeLoginMenu = () => closeMenu(MENU_LOGIN);
const loginMenuOpen = state => state.scratchGui.menus[MENU_LOGIN];
const openSerialportMenu = () => openMenu(MENU_LOGIN);
const closeSerialportMenu = () => closeMenu(MENU_LOGIN);
const serialportMenuOpen = state => state.scratchGui.menus[MENU_LOGIN];
const openSettingMenu = () => openMenu(MENU_SETTING);
const closeSettingMenu = () => closeMenu(MENU_SETTING);
const settingMenuOpen = state => state.scratchGui.menus[MENU_SETTING];

const setLinkStatus = (linkStatus) => ({
    type: SET_LINK_STATUS,
    status: linkStatus
});

export {
    reducer as default,
    initialState as menuInitialState,
    openAboutMenu,
    closeAboutMenu,
    aboutMenuOpen,
    openAccountMenu,
    closeAccountMenu,
    accountMenuOpen,
    openFileMenu,
    closeFileMenu,
    fileMenuOpen,
    openEditMenu,
    closeEditMenu,
    editMenuOpen,
    openLanguageMenu,
    closeLanguageMenu,
    languageMenuOpen,
    openLoginMenu,
    closeLoginMenu,
    loginMenuOpen,
    openSerialportMenu,
    closeSerialportMenu,
    serialportMenuOpen,
    openSettingMenu,
    closeSettingMenu,
    settingMenuOpen,
    setLinkStatus
};
