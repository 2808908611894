const SET_UPLOAD_MODE = 'scratch-arduino/progran-mode/SET_UPLOAD_MODE';
const SET_REALTIME_MODE = 'scratch-arduino/progran-mode/SET_REALTIME_MODE';
const SET_SUPPORT_SWITCH_MODE = 'scratch-arduino/progran-mode/SET_SUPPORT_SWITCH_MODE';
const SET_STAGE_TARGETPANE = 'scratch-arduino/progran-mode/SET_STAGE_TARGETPANE';

const initialState = {
    isRealtimeMode: true,
    isSupportSwitchMode: false,
    hideStageTargetPane: false,
};

const reducer = function (state, action) {
    if (typeof state === 'undefined') state = initialState;
    switch (action.type) {
    case SET_UPLOAD_MODE:
        return Object.assign({}, state, {
            isRealtimeMode: false
        });
    case SET_REALTIME_MODE:
        return Object.assign({}, state, {
            isRealtimeMode: true
        });
    case SET_SUPPORT_SWITCH_MODE:
        return Object.assign({}, state, {
            isSupportSwitchMode: action.state
        });
    case SET_STAGE_TARGETPANE:
        console.log('SET_STAGE_TARGETPANE');
        return Object.assign({}, state, {
            hideStageTargetPane: action.state
        });
    default:
        return state;
    }
};

const setUploadMode = () => ({
    type: SET_UPLOAD_MODE
});

const setRealtimeMode = () => ({
    type: SET_REALTIME_MODE
});

const setSupportSwitchMode = state => ({
    type: SET_SUPPORT_SWITCH_MODE,
    state: state
});

const setStageTargetPane = state => ({
    type: SET_SUPPORT_SWITCH_MODE,
    state: state
});

export {
    reducer as default,
    initialState as programModeInitialState,
    setUploadMode,
    setRealtimeMode,
    setSupportSwitchMode,
    setStageTargetPane
};
