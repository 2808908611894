function apiFetch(resolve, reject, uri, params) {
    var options = {
        headers:{
            'Accept': '*/*',         
            'Content-Type': 'application/json'
        },
        method: 'post',
        credentials: 'include'
    }
    if (params){
        if (params.headers) {options['headers'] =  Object.assign(options['headers'], params.headers)}
        if (params.method) {options["method"] = params.method}
        if (params.body) {options["body"] = params.body}
    }
    
    fetch(uri, options).then(response => {
        var body = response.json();
        if(response.status == 200) {
            return resolve(body);
        }
        return reject(body)
    }).catch(error => reject(error));
};


module.exports.requestSession = (resolve, reject, url) => (
    apiFetch(resolve, reject, url, {method: 'get'})
);

module.exports.requestSaveProjectTitle = (resolve, reject, url, projectTitle) => {
    const csrfToken = (document.cookie.includes('csrftoken')) ? document.cookie.split('; ').find(row => row.startsWith('csrftoken=')).split('=')[1] : '';
    let params = `{"name": "` + projectTitle + `"}`;
    apiFetch(resolve, reject, url, {headers: {'X-CSRFToken': csrfToken}, body: params})
};

module.exports.requestSaveProjectThumbnail = (resolve, reject, url, thumbnailBlob) => {
    const csrfToken = (document.cookie.includes('csrftoken')) ? document.cookie.split('; ').find(row => row.startsWith('csrftoken=')).split('=')[1] : '';
    apiFetch(resolve, reject, url, {headers:{'X-CSRFToken': csrfToken}, body: thumbnailBlob})
};
