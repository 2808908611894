const SET_SESSION = 'session/SET_SESSION';

const initialState = {
    session: null,
    userId: 0,    
    username: '',    
    avatar: '',
    projectId: null,
    projectName: '',
    canRemix: false,
    canSave: false,
    allowUploadThumbnail: false,
    authorUsername: '',
    authorThumbnailUrl: ''
}; 

const reducer = function (state, action) {
    if (typeof state === 'undefined') state = initialState;
    switch (action.type) {
        case SET_SESSION:
            return Object.assign({}, state, {
                userid: action.session.user_id,
                username: action.session.username,
                avatar: action.session.avatar,
                projectId: action.session.project_id,
                projectName: action.session.project_name,
                canRemix: !action.session.own_project,
                canSave: action.session.own_project,
                allowUploadThumbnail: action.session.allow_upload_thumbnail,
                authorUsername: action.session.author_username,
                authorThumbnailUrl: action.session.author_thumbnail_url
            });
    }
    return state;
};

const setSession = session => ({
    type: SET_SESSION,
    session: session
});

export {
    reducer as default,
    initialState as sessionInitialState,
    setSession
};